/*
 * verification slice
 */

import { createSlice } from "@reduxjs/toolkit";

const verificationSlice = createSlice({
  name: "verification",
  initialState: {
    verificationPending: false,
    error: null,
  },
  reducers: {
    resetState(state) {
      state.verificationPending = false;
      state.error = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const { resetState, resetError } = verificationSlice.actions;

export default verificationSlice.reducer;
