import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import accountRecovery from "features/Auth/AccountRecovery/accountRecoverySlice";
import passwordReset from "features/Auth/PasswordReset/passwordResetSlice";
import login from "features/Auth/Login/loginSlice";
import registration from "features/Auth/Registration/registrationSlice";
import verification from "features/Auth/Verification/verificationSlice";
import currentUser from "layouts/MainLayout/currentUserSlice";
import access from "features/Auth/Access/accessSlice";
import authModule from "./auth";
import investorModule from "./investors";
import systemConfig from "modules/systemConfig";

import { browserHistory as history } from "lib/history";

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    router: connectRouter(history),
    accountRecovery,
    passwordReset,
    login,
    registration,
    verification,
    currentUser,
    access,
    authModule,
    investorModule,
    systemConfig,
    ...injectedReducers,
  });
}
