import { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

const AuthLayout = lazy(() => import("layouts/AuthLayout"));

const RoutingGeneral = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/accounts">
          <AuthLayout />
        </Route>
        <Route path={"/"}>{null}</Route>
      </Switch>
    </Suspense>
  );
};

export default RoutingGeneral;
