/*
  Render meta tags in page head with React Helmet - canonical url
*/

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
interface TagCanonicalObject {
  canonical:String
}

const TagCanonical = (object:TagCanonicalObject) => {
  let absoluteUrl:string = object.canonical.replace(/^\/*/g, ""); // trim leading "/"
  if (!absoluteUrl.startsWith("http")) {
    absoluteUrl = `https://www.cashflowportal.com/${absoluteUrl}`;
  }
  return (
    <Helmet>
      <meta property="og:url" content={absoluteUrl} />
      <link rel="canonical" href={absoluteUrl} />
    </Helmet>
  );
};

TagCanonical.propTypes = {
  canonical: PropTypes.string.isRequired,
};

export default TagCanonical;
