/*
 *  Recover account slice
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "common/utils";

export const recoverAccount = createAsyncThunk(
  "accountRecovery/recoverAccount",
  async (param, { extra, rejectWithValue }) => {
    const { api } = extra;
    const { email, customSubdomain, slug } = param || {};
    try {
      const response = await api.post("/v1/accounts/forgot_password", {
        email_address: email,
        redirect_url:
          slug && !customSubdomain
            ? `/portal/${slug}/new-password`
            : "/accounts/new-password",
        custom_subdomain: customSubdomain,
      });

      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  pending: false,
  message: "",
  error: null,
  accessToken: "",
};

const accountRecoverySlice = createSlice({
  name: "accountRecovery",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
    },
  },
  extraReducers: {
    [recoverAccount.rejected]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
    [recoverAccount.fulfilled]: (state) => {
      state.pending = false;
      state.error = null;
    },
    [recoverAccount.pending]: (state) => {
      state.pending = true;
    },
  },
});

export const { resetState } = accountRecoverySlice.actions;

export default accountRecoverySlice.reducer;
