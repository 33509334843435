import { createSlice } from "@reduxjs/toolkit";

const authModalSlice = createSlice({
  name: "authModal",
  initialState: {
    loginSuccess: false,
    isSignIn: false,
    isRegistration: false,
    isExpressInterest: false,
    isInvestor: false,
    isMarketplace: false,
    secondaryOption: null,
    visible: false,
    redirectUrl: "",
    titleBody: "",
    userToken: "",
    preAuthInfo: {},
  },
  reducers: {
    resetAuthModalState(state) {
      state.loginSuccess = false;
      state.isSignIn = false;
      state.isRegistration = false;
      state.isExpressInterest = false;
      state.isInvestor = false;
      state.isMarketplace = false;
      state.secondaryOption = null;
      state.visible = false;
      state.redirectUrl = "";
      state.userToken = "";
      state.preAuthInfo = {};
    },
    resetPreAuthInfo(state) {
      state.preAuthInfo = {};
    },
    setUserToken(state, { payload }) {
      state.userToken = payload;
    },
    setLoginSuccess(state, { payload }) {
      state.loginSuccess = payload;
    },
    setSignIn(state, { payload }) {
      state.isSignIn = payload;
      if (payload) {
        state.isRegistration = false;
        state.isExpressInterest = false;
      }
    },
    setRegistration(state, { payload }) {
      state.isRegistration = payload;
      if (payload) {
        state.isExpressInterest = false;
        state.isSignIn = false;
      }
    },
    setExpressInterest(state, { payload }) {
      state.isExpressInterest = payload;
      if (payload) {
        state.isRegistration = false;
        state.isSignIn = false;
      }
    },
    setSecondaryOption(state, { payload }) {
      state.secondaryOption = payload;
    },
    setPreAuthInfo(state, { payload }) {
      state.preAuthInfo = payload;
    },
    showAuthModal(state, { payload }) {
      state.visible = true;

      if (typeof payload.isRegistration !== "undefined") {
        state.isRegistration = payload.isRegistration;
      }

      if (typeof payload.isExpressInterest !== "undefined") {
        state.isExpressInterest = payload.isExpressInterest;
      }

      if (typeof payload.isInvestor !== "undefined") {
        state.isInvestor = payload.isInvestor;
      }

      if (typeof payload.isMarketplace !== "undefined") {
        state.isMarketplace = payload.isMarketplace;
      }

      if (typeof payload.redirectUrl === "string") {
        state.redirectUrl = payload.redirectUrl;
      } else {
        state.redirectUrl = "";
      }

      if (typeof payload.titleBody === "string") {
        state.titleBody = payload.titleBody;
      } else {
        state.titleBody = "";
      }

      if (typeof payload.preAuthInfo !== "undefined") {
        state.preAuthInfo = payload.preAuthInfo;
      }
    },
    hideAuthModal(state) {
      state.visible = false;
      state.redirectUrl = "";
    },
  },
});

export const {
  resetAuthModalState,
  setSignIn,
  setRegistration,
  setExpressInterest,
  setPreAuthInfo,
  setSecondaryOption,
  showAuthModal,
  hideAuthModal,
  setLoginSuccess,
  setUserToken,
  resetPreAuthInfo,
} = authModalSlice.actions;

export default authModalSlice.reducer;
