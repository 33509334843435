import axios from "axios";
import { message } from "antd";
import {
  getToken,
  deleteToken,
  getRefreshToken,
  deleteRefreshToken,
  setToken,
  getCookieToken,
  getCookieRefreshToken,
  clear,
  clearCredentialCookies,
} from "./helpers";
import { browserHistory } from "./history";
import { encodeOrigin } from "common/constants/sso";

/**
 * HTTP Client (axios instance) that interacts with the flask api that
 * doesn't need Authorization
 */

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(null, (err) => {
  return Promise.reject(err);
});

const refreshTokenAuthHeader = () => {
  const cookieToken = getCookieRefreshToken();
  if (cookieToken) {
    return `Bearer ${cookieToken}`;
  }

  return `Bearer ${getRefreshToken()}`;
};

const accessTokenAuthHeader = () => {
  const cookieToken = getCookieToken();
  const token = getToken();

  if (cookieToken) {
    return `Bearer ${cookieToken}`;
  }

  if (token) {
    return `Bearer ${token}`;
  }

  return null;
};

export const createRefreshTokenClient = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      Authorization: refreshTokenAuthHeader(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const refreshTokenApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    Authorization: refreshTokenAuthHeader(),
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

refreshTokenApi.interceptors.response.use(null, (err) => {
  if (!err.response) {
    message.error("Something went wrong.");
    return;
  }

  const { response } = err;
  // refresh token expires
  if (response.status === 401 || response.status === 422) {
    deleteRefreshToken();
    deleteToken();
    clear();
    clearCredentialCookies();
  }
  return Promise.reject(err);
});

/**
 * HTTP Client (axios instance )that interacts with the flask api that
 * passes Authorization and other headers needed
 */
export function createAuthenticatedClient() {
  const authenticatedApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      Authorization: accessTokenAuthHeader(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  authenticatedApi.interceptors.response.use(null, (err) => {
    if (!err.response) {
      message.error("Something went wrong.");
      return;
    }

    const { response } = err;
    let originalRequest = err.config;

    // if the reason is access token expires, refresh token and re-try
    if (
      response.status === 401 &&
      !originalRequest._retry &&
      response?.data?.code === "token_expired"
    ) {
      originalRequest._retry = true;
      deleteToken();
      return refreshTokenApi
        .post(
          `/v1/accounts/refresh?sso_origin=${encodeOrigin()}`,
          {},
          { withCredentials: true }
        )
        .then((res) => {
          if (res.status === 201) {
            // reset token
            setToken(res.data.access_token);
            // change request header
            originalRequest.headers["Authorization"] = accessTokenAuthHeader();
            // re-request
            return authenticatedApi.request(originalRequest);
          }
        });
    }

    // if the user's subscription expired, send them to the paywall page
    if (
      response.status === 403 &&
      response?.data?.code === "subscription_expired"
    ) {
      browserHistory.push("/app/subscribe");
    }

    if (response.status === 422) {
      message.error("You may need to log back in.");
    }

    return Promise.reject(err);
  });

  return authenticatedApi;
}

export default api;
