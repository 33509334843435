import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "common/utils";
import { parentDomain } from "common/constants/sso";
import { setRefreshToken, setToken } from "lib/helpers";
import { sendPayload } from "common/constants/sso";


export const register = createAsyncThunk(
  "registration/register",
  async (params, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();
    const originSite = parentDomain.parentOrigin;
    let url = `/v1/accounts/register?origin_site=${originSite}`;
    if (params.subdomain) {
      url = `/v1/accounts/register?origin_site=${originSite}&subdomain=${params.subdomain}`;
      delete params.subdomain;
    } else if (params.slug) {
      url = `/v1/accounts/register?origin_site=${originSite}&portal_slug=${params.slug}`;
      delete params.slug;
    }

    try {
      const response = await api.post(url, params);
      return response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

export const registerFB = createAsyncThunk(
  "registration/register",
  async (params, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();
    const originSite = parentDomain.parentOrigin;
    let url = `/v1/accounts/register_fb?origin_site=${originSite}`;
    if (params.subdomain) {
      url = `/v1/accounts/register_fb?origin_site=${originSite}&subdomain=${params.subdomain}`;
      delete params.subdomain;
    } else if (params.slug) {
      url = `/v1/accounts/register_fb?origin_site=${originSite}&portal_slug=${params.slug}`;
      delete params.slug;
    }

    try {
      const response = await api.post(url, params);
      setToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      const payload = {
        redirectUrl: "/app",
        message: "Redirect",
      };
      sendPayload(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);


export const isEmailRegistered = createAsyncThunk(
  "registration/is_email_registered",
  async (emailAddress, thunkAPI) => {
    // do I need to add /portal/ in here?
    // not necessarily, since does same check whether or not in portal
    const url = "/v1/accounts/is_registered_email";
    const api = thunkAPI.extra.createAuthenticatedClient();
    const trimmedEmail = emailAddress.trim().toLowerCase();

    // if email empty string, invalid request
    if (trimmedEmail) {
      const isRegisteredParams = { email_address: trimmedEmail };

      try {
        const response = await api.post(url, isRegisteredParams);
        const responseBody = await response.data;
        if (responseBody.registered_email) {
          return trimmedEmail;
        } else {
          return "";
        }
      } catch (e) {
        // TODO is this the right thing to do here?
        // should probably handle specific error cases
        return thunkAPI.rejectWithValue(getErrorMessage(e));
      }
    } else {
      return "";
    }
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    registrationPending: false,
    error: null,
    registeredEmail: "",
  },
  reducers: {
    resetState(state) {
      // TODO just set to initial state
      state.registrationPending = false;
      state.error = null;
      state.registeredEmail = "";
    },
  },
  extraReducers: {
    [register.fulfilled]: (state) => {
      state.registrationPending = false;
      state.error = null;
    },
    [register.pending]: (state) => {
      state.registrationPending = true;
    },
    [register.rejected]: (state, action) => {
      state.registrationPending = false;
      state.error = action.payload;
    },
    [isEmailRegistered.fulfilled]: (state, action) => {
      state.registeredEmail = action.payload;
    },
  },
});

export const { resetState } = registrationSlice.actions;

export default registrationSlice.reducer;

// SELECTORS

/**
 * Selector for whether the email belongs to a registered user.
 *
 * @param {} state the redux state
 * @returns {boolean} true if belongs to a registered user, false otherwise
 */
export const selectIsRegisteredEmail = (state) => {
  // get truthy value
  // only empty string is falsy
  return state.registration.registeredEmail;
};

/**
 * Selector for an existing registered email address.
 *
 * @param {} state the redux state
 * @returns {string} the registered email, or "" if no such email exists
 */
export const selectRegisteredEmail = (state) => {
  return state.registration.registeredEmail;
};
