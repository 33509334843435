import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { parentDomain } from "common/constants/sso";
import { EXPRESS_INTEREST_STAGE } from "./constants";

export const expressInterest = createAsyncThunk(
  "expressInterest/existingUser",
  async ({ offeringSlug, payload }, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();

    try {
      const { data } = await api.post(
        `/v1/offerings/${offeringSlug}/express-interest`,
        payload
      );

      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const expressInterestNewUser = createAsyncThunk(
  "expressInterest/newUser",
  async ({ offeringSlug, payload }, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();
    const originSite = parentDomain.parentOrigin;

    try {
      const { data } = await api.post(
        `/v1/offerings/${offeringSlug}/express-interest/new?origin_site=${originSite}`,
        payload
      );

      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const expressInterestSlice = createSlice({
  name: "expressInterest",
  initialState: {
    hasExpressedInterest: false,
    isExpressingInterest: false,
    currentStage: EXPRESS_INTEREST_STAGE,
    offeringSlug: null,
    expressInterestPending: false,
    error: null,
  },
  reducers: {
    checkIsExpressingInterest(state) {
      if (state.currentStage === EXPRESS_INTEREST_STAGE) {
        state.isExpressingInterest = true;
      }
    },
    toNextExpressInterestStage(state) {
      ++state.currentStage;
      checkIsExpressingInterest(state);
      if (state.currentStage > EXPRESS_INTEREST_STAGE) {
        state.hasExpressedInterest = true;
      }
    },
    toLastExpressInterestStage(state) {
      --state.currentStage;
      checkIsExpressingInterest(state);
      if (state.currentStage <= EXPRESS_INTEREST_STAGE) {
        state.hasExpressedInterest = false;
      }
    },
    setOfferingSlug(state, { payload }) {
      state.offeringSlug = payload;
    },
    resetExpressInterest(state) {
      state.hasExpressedInterest = false;
      state.isExpressingInterest = false;
      state.currentStage = EXPRESS_INTEREST_STAGE;
      state.offeringSlug = null;
      state.expressInterestPending = false;
      state.error = null;
    },
  },
  extraReducers: {
    [expressInterest.fulfilled]: (state) => {
      state.expressInterestPending = false;
      state.error = null;
    },
    [expressInterest.pending]: (state) => {
      state.expressInterestPending = true;
    },
    [expressInterest.rejected]: (state, action) => {
      state.expressInterestPending = false;
      state.error = action.error;
    },
    [expressInterestNewUser.fulfilled]: (state) => {
      state.expressInterestPending = false;
      state.error = null;
    },
    [expressInterestNewUser.pending]: (state) => {
      state.expressInterestPending = true;
    },
    [expressInterestNewUser.rejected]: (state, action) => {
      state.expressInterestPending = false;
      state.error = action.error;
    },
  },
});

const { checkIsExpressingInterest } = expressInterestSlice.actions;

export const {
  toNextExpressInterestStage,
  toLastExpressInterestStage,
  setOfferingSlug,
  resetExpressInterest,
} = expressInterestSlice.actions;

export default expressInterestSlice.reducer;
