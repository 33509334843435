import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSSO from "./useSSO";

import { normal } from "common/constants/meta-tags";
import PageHeadTags from "features/Core/PageHeadTags";
import RoutingGeneral from "./RoutingGeneral";
import { getAllowedDomains, getWriteOnlyDomains } from "common/constants/sso";
import { getSystemConfigs } from "modules/systemConfig";
import { tokenKey, refreshTokenKey, firebaseTokenKey } from "lib/helpers";

const App = () => {
  const { handleEvent, handleStorage } = useSSO();
  const dispatch = useDispatch();
  useEffect(() => {
    const receiveMessage = (event: any) => {
      if (
        !getAllowedDomains().includes(event.origin) &&
        !getWriteOnlyDomains().includes(event.origin)
      )
        return;

      handleEvent(event);
    };

    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage);
    
  }, [handleEvent]);
  
  useEffect(() => {
    if (window.location === window.parent.location) {
      window.location.href = "https://www.cashflowportal.com";
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    dispatch(getSystemConfigs());
  }, [dispatch]);

  

  useEffect(() => {
    const handleStorageEvent = (storageEvent: any) => {
      if (
        storageEvent.key === tokenKey ||
        storageEvent.key === refreshTokenKey ||
        storageEvent.key === firebaseTokenKey
      ) {
        handleStorage(storageEvent);
      }
    };

    window.addEventListener("storage", handleStorageEvent, false);

    return () => window.removeEventListener("storage", handleStorageEvent);
  }, [handleStorage]);

  return (
    <>
      {/*
        Default page head tags - If a view changes a tag, the default must
        be added here, or it will not reset when the view unloads
      */}
      <PageHeadTags {...normal} />
      <RoutingGeneral />
    </>
  );
};

export default App;
