/*
  Render meta tags in page head with React Helmet - page title
*/

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
interface TagTitleInterface {
  title: string
}

const TagTitle = (object:TagTitleInterface) => {
  const fullTitle = object.title.trim();
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
    </Helmet>
  );
};

TagTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TagTitle;
