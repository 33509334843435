/*
  Authentication reducers
 */

import { combineReducers } from "@reduxjs/toolkit";

import authModal from "features/Auth/AuthModal/authModalSlice";
import accountConfirmation from "features/Auth/Confirmation/confirmationSlice";

const authReducer = combineReducers({
  authModal,
  accountConfirmation,
});

export default authReducer;
