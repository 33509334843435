import ReactDOM from "react-dom";

import { ApolloProvider } from "@apollo/client";
import { client } from "common/graphql/graphqlClient";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";

import { browserHistory as history } from "lib/history";

import AppView from "./features/App";
import "./index.css";

import * as serviceWorker from "./serviceWorker";
import configureStore from "configureStore";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={AppView} />
        </Switch>
      </ConnectedRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
