// Note: page title & description are also defined in index.html
export const robots = {
  indexable: '<meta name="robots" content="index, follow"',
  notIndexable: '<meta name="robots" content="noindex, nofollow"',
};
  
export const normal = {
  title: "Cash Flow Authentication",
  description:
    "SSO Server for Cash Flow",
  canonical: "https://www.auth.cashflowportal.com/",
};

