import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "common/utils";
import { setRefreshToken, setToken } from "lib/helpers";
import { encodeOrigin, sendPayload } from "common/constants/sso";

export const accessAccount = createAsyncThunk(
  "access/accessAccount",
  async (payload, { dispatch, extra, rejectWithValue }) => {
    const { api } = extra;
    try {
      const { data } = await api.post(
        `/v1/accounts/access/verify?sso_origin=${encodeOrigin()}`,
        {
          token: payload,
        },
        {
          withCredentials: true,
        }
      );

      setToken(data.access_token);
      setRefreshToken(data.refresh_token);
      const payload = {
        redirectUrl: "/app",
        message: "Redirect",
      };
      sendPayload(payload);

      return data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  pending: true,
  token: null,
  error: null,
};

const accessSlice = createSlice({
  name: "access",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(accessAccount.fulfilled, (state) => {
        state.pending = false;
        state.error = false;
      })
      .addCase(accessAccount.pending, (state) => {
        state.pending = false;
      })
      .addCase(accessAccount.rejected, (state, { payload }) => {
        state.error = payload;
        state.pending = false;
      }),
});

export default accessSlice.reducer;
