/*
  Render meta tags in page head with React Helmet - noindex, nofollow
*/

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
interface TagNoindexInterface {
  noindex:boolean
}
const TagNoindex = (object:TagNoindexInterface) => {
  return (
    <Helmet>
      <meta
        name="robots"
        content={object.noindex ? "noindex, nofollow" : "index, follow"}
      />
    </Helmet>
  );
};

TagNoindex.propTypes = {
  noindex: PropTypes.bool,
};

export default TagNoindex;
