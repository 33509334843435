import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "common/utils";
import { setRefreshToken, setToken, setFirebaseToken } from "lib/helpers";
import { encodeOrigin, parentDomain } from "common/constants/sso";

export const confirmAccount = createAsyncThunk(
  "confirmation/confirmAccount",
  async ({ token, subdomain }, { extra, rejectWithValue }) => {
    const { api } = extra;
    const originSite = parentDomain.parentOrigin;
    try {
      const { data } = await api.post(
        `/v1/accounts/verify?sso_origin=${encodeOrigin()}&origin_site=${originSite}`,
        {
          token,
        },
        {
          withCredentials: true,
        }
      );

      setToken(data.access_token);
      setRefreshToken(data.refresh_token);
      setFirebaseToken(data.firebase_token);

      return data;
    } catch (err) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  pending: false,
  error: null,
};

const confirmationSlice = createSlice({
  name: "confirmation",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(confirmAccount.fulfilled, (state) => {
        state.pending = false;
        state.error = null;
      })
      .addCase(confirmAccount.pending, (state) => {
        state.pending = true;
      })
      .addCase(confirmAccount.rejected, (state, { payload }) => {
        state.pending = false;
        state.error = payload;
      });
  },
});

export default confirmationSlice.reducer;
