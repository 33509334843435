/*
  Render meta tags in page head with React Helmet - page description
*/

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
interface TagDescriptionObject {
  description:string
}
const TagDescription = (object:TagDescriptionObject) => {
  return (
    <Helmet>
      <meta name="description" content={object.description} />
      <meta name="og:description" content={object.description} />
    </Helmet>
  );
};

TagDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default TagDescription;
