import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, getErrorMessage } from "common/utils";
import { message } from "antd";

export const SPONSOR = "Sponsor";
export const INVESTOR = "Investor";
export const MASQUERADING = "Masquerading";

export const getCurrentUser = createAsyncThunk(
  "currentUser/getCurrentUser",
  async (params, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();
    try {
      const { data } = await api.get("/v1/accounts/me");
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const switchAccountType = createAsyncThunk(
  "accounts/switchAccountType",
  async (params, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();

    try {
      const { data } = await api.patch("/v1/accounts/switch_type", params);

      const accountType = data?.active_account_type?.name;
      const accountName =
        accountType === "Investor" ? "investing" : "syndicating";
      const messageText = `Successfully switched ${
        accountType ? `to ${accountName}` : "account type"
      }`;

      message.success(messageText, 5);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserPortals = createAsyncThunk(
  "currentUser/getUserPortals",
  async (userId, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();

    try {
      const { data } = await api.get(`/v1/custom_portals/user/${userId}`);

      return data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const initialState = {
  showFirstLoginEverMessage: false,
  currentUser: null,
  accountType: null,
  pending: false,
  error: null,
  userPortals: [],
  pendingPortals: false,
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
    },
    setSubscriptionStatus(state, payload) {
      state.currentUser.has_subscription = payload;
    },
    setShowFirstLoginEverMessage(state) {
      state.showFirstLoginEverMessage = true;
    },
  },
  extraReducers: {
    [getCurrentUser.pending]: (state) => {
      state.pending = true;
    },
    [getCurrentUser.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.currentUser = payload;
      state.accountType = payload.is_on_behalf_of
        ? MASQUERADING
        : get(payload, "active_account_type.name", null);
    },
    [getCurrentUser.error]: (state, { payload, error }) => {
      state.pending = false;

      if (payload) {
        state.error = payload.message;
      } else {
        state.error = error;
      }
    },
    [switchAccountType.pending]: (state) => {
      state.pending = true;
    },
    [switchAccountType.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.accountType = payload.is_on_behalf_of
        ? MASQUERADING
        : get(payload, "active_account_type.name", null);
    },
    [switchAccountType.error]: (state, { payload, error }) => {
      state.pending = false;
      if (payload) {
        state.error = payload.message;
      } else {
        state.error = error;
      }
    },
    [getUserPortals.pending]: (state) => {
      state.pendingPortals = true;
    },
    [getUserPortals.fulfilled]: (state, { payload }) => {
      state.pendingPortals = false;
      state.userPortals = payload.user_portals;
    },
    [getUserPortals.rejected]: (state) => {
      state.pendingPortals = false;
    },
  },
});

export const {
  resetState,
  setSubscriptionStatus,
  setShowFirstLoginEverMessage,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
