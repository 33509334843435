/*
 * password reset slice
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { message } from "antd";
import { getErrorMessage } from "common/utils";

export const verifyToken = createAsyncThunk(
  "passwordReset/verifyToken",
  async (param, { extra, rejectWithValue }) => {
    const { api } = extra;
    const { token } = param || {};
    try {
      const response = await api.post(
        "/v1/accounts/reset_password/verify",
        {
          token,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "passwordReset/resetPassword",
  async (param, { getState, extra, rejectWithValue, dispatch }) => {
    const { api } = extra;
    const state = getState();
    const token = state.passwordReset.accessToken;
    const { password, slug } = param || {};
    try {
      const response = await api.post("/v1/accounts/reset_password", {
        token,
        password,
      });

      message.success("Your password has been updated", 5);

      if (slug) {
        dispatch(push(`/portal/${slug}/login`));
      } else {
        dispatch(push("/accounts/login"));
      }
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  pending: false,
  message: "",
  verificationError: false,
  resetPasswordError: false,
  resetPasswordErrorMessage: "",
  accessToken: "",
  error: null,
};

const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
    },
  },
  extraReducers: {
    [verifyToken.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.accessToken = payload.access_token;
    },
    [verifyToken.pending]: (state) => {
      state.pending = true;
    },
    [verifyToken.rejected]: (state, { payload }) => {
      state.pending = false;
      state.verificationError = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.error = null;
    },
    [resetPassword.pending]: (state, { payload }) => {
      state.pending = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.pending = false;
      state.error = payload.message;
    },
  },
});

export const { resetState } = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
