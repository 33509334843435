import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setToken, setRefreshToken, getRmbmeToken, setFirebaseToken } from "lib/helpers";
import { getErrorMessage } from "common/utils";
import { encodeOrigin } from "common/constants/sso";

export const login = createAsyncThunk(
  "login/login",
  async ({ email, password }, { rejectWithValue, extra }) => {
    const { api } = extra;
    const rmbmeToken = getRmbmeToken();

    try {
      const { data } = await api.post(
        `/v1/accounts/auth?sso_origin=${encodeOrigin()}`,
        {
          email_address: email,
          password,
        },
        {
          withCredentials: true,
          ...(rmbmeToken && {
            'X-Rmbme': getRmbmeToken,
          }),
        }
      );

      if (data.access_token) {
        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        setFirebaseToken(data.firebase_token);
      }
      return data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

export const addContactFromLink = createAsyncThunk(
  "login/addContactFromLink",
  async (slug, { dispatch, extra }) => {
    const api = extra.createAuthenticatedClient();
    const { data } = await api.post("/v1/loggedin", {
      portal_slug: slug,
    });

    return data;
  }
);

export const logout = createAsyncThunk(
  "login/logout",
  async (payload, { extra, getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const api = extra.createAuthenticatedClient();
    const spoof = state.currentUser?.currentUser?.meta?.spoof_session_id;

    // terminate thunk if this is a normal session (not spoofed)
    if (!spoof) {
      await api.post(`/v1/auth/logout?sso_origin=${encodeOrigin()}`, {}, { withCredentials: true });
      dispatch(resetState());
      return true;
    }

    await api.post(`/v1/auth/logout?sso_origin=${encodeOrigin()}`, {}, { withCredentials: true });
    // process spoof cleanup
    try {
      await api.delete(`/v1/accounts/access?session_id=${spoof}`);
      dispatch(resetState());
      return true;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginPending: false,
    error: null,
  },
  reducers: {
    resetState(state) {
      state.loginPending = false;
      state.error = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state) => {
      state.loginPending = false;
      state.error = null;
    },
    [login.pending]: (state) => {
      state.loginPending = true;
    },
    [login.rejected]: (state, action) => {
      state.loginPending = false;
      state.error = action.payload;
    },
  },
});

export const { resetState } = loginSlice.actions;

export default loginSlice.reducer;
