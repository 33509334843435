/*
  Render meta tags in page head with React Helmet
*/

import PropTypes from "prop-types";
import TagTitle from "./TagTitle";
import TagDescription from "./TagDescription";
import TagCanonical from "features/Core/PageHeadTags/TagCanonical";
import TagNoindex from "features/Core/PageHeadTags/TagNoindex";
interface PageHeadObject {
  title?:string,
  description?:string,
  canonical?:string,
  noindex?:boolean
}

const PageHeadTags = (object:PageHeadObject) => {
  return (
    <>
      {object.title && <TagTitle title={object.title} />}
      {object.description && <TagDescription description={object.description} />}
      {object.canonical && <TagCanonical canonical={object.canonical} />}
      <TagNoindex noindex={object.noindex ? object.noindex : false} />
    </>
  );
};

PageHeadTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  noindex: PropTypes.bool,
};

export default PageHeadTags;
