import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken, getCookieToken } from "lib/helpers";
// TODO (Allen): Add this to config vars
const GRAPHQL_API_URL = process.env.REACT_APP_API_URL + "/graphql/";

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const cookieToken = getCookieToken();
  const localStorageToken = getToken();

  return {
    headers: {
      ...headers,
      authorization: cookieToken
        ? `Bearer ${cookieToken}`
        : localStorageToken && `Bearer ${localStorageToken}`,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
