import Cookies from "universal-cookie";
import { sendPayload } from "common/constants/sso";
const cookies = new Cookies();

export const tokenKey = "TOKEN";
export const refreshTokenKey = "REFRESH_TOKEN";
export const firebaseTokenKey = "FIREBASE_TOKEN";
export const rmbmeTokenKey = "RMBME";
export const tokenCookieKey = "__access_token";
export const refreshTokenCookieKey = "__refresh_token";
export const firebaseTokenCookieKey = "__firebase_token";
export const rmbmeTokenCookieKey = "__rmbme";

export const isIncognito = () => {
  try {
    const token = window.localStorage.getItem(tokenKey)
    return false
  } catch (e) {
    return true
  }
}

export const getTokenForKey = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    const message = {
      message: "IsIncognito",
      isIncognito: true,
    }
    sendPayload(message)
    return "";
  }
}

export const getToken = () => {
  return getTokenForKey(tokenKey);
}

export const getRefreshToken = () => {
  return getTokenForKey(refreshTokenKey);
}

export const getFirebaseToken = () => {
  return getTokenForKey(firebaseTokenKey);
}

export const getRmbmeToken = () => {
  return getTokenForKey(rmbmeTokenKey);
}

export const getCookieToken = () => {
  return cookies.get(tokenCookieKey);
}

export const getCookieRefreshToken = () => {
  return cookies.get(refreshTokenCookieKey);
}

export const getCookieFirebaseToken = () => {
  return cookies.get(firebaseTokenCookieKey);
}

export const setTokenForkey = (key: string, token: string) => {
  try {
    return window.localStorage.setItem(key, token);
  } catch (e) {
    const message = {
      message: "UpdateLocalStorage",
      key,
      newValue: token,
      isIncognito: true,
    }
    sendPayload(message);
  }
}

export const setToken = (token: string) => {
  setTokenForkey(tokenKey, token);
}

export const setRefreshToken = (token: string) => {
  setTokenForkey(refreshTokenKey, token);
}

export const setFirebaseToken = (token: string) => {
  setTokenForkey(firebaseTokenKey, token);
}

export const setRmbmeToken = (token: string) => {
  setTokenForkey(rmbmeTokenKey, token);
}

const setExpires = () => {
  const expires = new Date(new Date().setDate(new Date().getDate() + 30));
  return expires.toUTCString();
};

export const deleteTokenForKey = (key: string) => {
  try {
    return window.localStorage.removeItem(key);
  } catch (e) {
    const message = {
      message: "IsIncognito",
      isIncognito: true,
    }
    sendPayload(message)
  }
}

export const deleteToken = () => {
  deleteTokenForKey(tokenKey);
}

export const deleteRefreshToken = () => {
  deleteTokenForKey(refreshTokenKey);
}

export const deleteFirebaseToken = () => {
  deleteTokenForKey(firebaseTokenKey);
}

export const deleteRmbmeToken = () => {
  deleteTokenForKey(rmbmeTokenKey);
}

export const save = (name: string, data: string) => {
  try {
    return window.localStorage.setItem(name, data);
  } catch (e) {
    const message = {
      message: "IsIncognito",
      isIncognito: true,
    }
    sendPayload(message)
  }
}

export const retrieve = (name: string) => {
  try {
    return window.localStorage.getItem(name);
  } catch (e) {
    const message = {
      message: "IsIncognito",
      isIncognito: true,
    }
    sendPayload(message)
  }
}


export const clear = () => {
  try {
    return window.localStorage.clear();
  } catch (e) {
    const message = {
      message: "IsIncognito",
      isIncognito: true,
    }
    sendPayload(message)
  }

}

const getDomain = () => {
  if (process.env.REACT_APP_ENV === "development") {
    return ".cashflowportal.dev";
  } else if (process.env.REACT_APP_ENV === "production") {
    return ".cashflowportal.com";
  } else if (process.env.REACT_APP_ENV === "staging") {
    return ".cashflowportal-staging.net";
  } else {
    return null;
  }
};

const get_cookie = (name: string) => {
  return document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
}
export const add_cookie = (name: any, content: any) => {
  const domain = getDomain();
  const expires = setExpires();
  document.cookie =
    name +
    "=" +
    content +
    ";path=/" +
    ";samesite=None" +
    ";Secure" +
    (domain ? ";domain=" + domain : "") +
    (expires ? ";expires=" + expires : "");
};

const delete_cookie = (name: string) => {
  if (get_cookie(name)) {
    const del_cookie = name +
      "=" +
      ";path=/" +
      ";domain=" + getDomain() +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = del_cookie;
  }
}

export const clearCredentialCookies = () => {
  delete_cookie(tokenCookieKey);
  delete_cookie(refreshTokenCookieKey);
  delete_cookie(firebaseTokenCookieKey);
}