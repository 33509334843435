import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

const selectSystemConfig = (state) => state.systemConfig.systemConfig;
export const selectGetSystemConfigPending = (state) =>
  state.systemConfig.getSystemConfigPending;

export const selectSystemConfigWithKey = createSelector(
  selectSystemConfig,
  (_, key) => key,
  (systemConfig, key) => {
    const result = systemConfig[key];
    return result;
  }
);

export const getSystemConfig = createAsyncThunk(
  "systemConfig/getSystemConfig",
  async (config_key, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();
    try {
      const { data } = await api.post("/v1/system_config", {
        config_key: config_key,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSystemConfigs = createAsyncThunk(
  "systemConfig/getSystemConfigs",
  async (params, { extra, rejectWithValue }) => {
    const api = extra.createAuthenticatedClient();
    try {
      const { data } = await api.get("/v1/system_config");
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  systemConfig: {},
  getSystemConfigPending: true,
  getSystemConfigError: null,
  getSystemConfigsPending: true,
  getSystemConfigsError: null,
};

const systemConfigSlice = createSlice({
  name: "systemConfig",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    [getSystemConfig.fulfilled]: (state, { payload }) => {
      state.systemConfig[payload.config_key] = payload.config_value;
      state.getSystemConfigPending = false;
    },
    [getSystemConfig.pending]: (state) => {
      state.getSystemConfigPending = true;
    },
    [getSystemConfig.rejected]: (state, { payload }) => {
      state.getSystemConfigPending = false;
      state.getSystemConfigError = payload;
    },
    [getSystemConfigs.fulfilled]: (state, { payload }) => {
      state.systemConfig = payload;
      state.getSystemConfigsPending = false;
    },
    [getSystemConfigs.pending]: (state) => {
      state.getSystemConfigsPending = true;
    },
    [getSystemConfigs.rejected]: (state, { payload }) => {
      state.getSystemConfigsPending = false;
      state.getSystemConfigsError = payload;
    },
  },
});

export default systemConfigSlice.reducer;
