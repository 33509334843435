import { useLocation } from "react-router-dom";
// import moment from "moment";
import { getToken, getCookieToken } from "lib/helpers";
import { VISIBLE_COUNTRIES } from "common/constants/addresses";
import { getCountries } from "node-countries";

/*
  Utility Methods
  */

export const get = (value, path, defaultValue) => {
  const pathArray = Array.isArray(path) ? path : String(path).split(".");
  return pathArray.reduce((acc, v) => {
    try {
      acc = acc[v];
    } catch (e) {
      return defaultValue;
    }
    return acc;
  }, value);
};

// param: format overrides everything, not validated for correctness
// export const formatDate = (
//   value,
//   config = { yearOnly: false, longForm: false }
// ) => {
//   if (value) {
//     if (config.longForm) {
//       return moment(value).format("MMMM D, YY");
//     }
//     if (config.yearOnly) {
//       return new Date(value).getFullYear().toString();
//     }
//     if (typeof value === "string" && value.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
//       return value;
//     }
//     return moment(value).format("MM/DD/YYYY");
//   }
//   return null;
// };

// export const formatDateTimeShort = (value) => {
//   return moment(value).format("l LT");
// };

export const formatPercent = (value) => {
  const string = String(value);
  const lastChar = string.slice(-1) === "%" ? "" : "%";
  return string + lastChar;
};

export const formatYear = (value) => {
  return `${value} year${value > 1 ? "s" : ""}`;
};

export const formatInteger = (value) => {
  return Number(value).toLocaleString("en-US");
};

export const formatMoney = (
  amount,
  config = { showCents: false, inputDollars: false, short: false }
) => {
  if (isNaN(amount)) {
    return "$";
  }

  if (config.short) {
    if (amount > 99999999999) {
      return `$${Math.round(amount / 1000000000) / 100}B`;
    }
    if (amount > 99999999) {
      return `$${Math.round(amount / 1000000) / 100}MM`;
    }
    if (amount > 99999) {
      return `$${Math.round(amount / 100000)}K`;
    }
  }

  let options = {
    style: "currency",
    currency: "USD",
  };

  if (!config.inputDollars) {
    amount = amount / 100;
  }

  if (!config.showCents) {
    options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options,
    };
  }

  return new Intl.NumberFormat("en-US", options).format(amount);
};

export const formatAddress = (components) => {
  if (
    components === null ||
    typeof components !== "object" ||
    !Object.keys(components).length
  ) {
    return "";
  }

  const address_first = [
    components.addressLine1 || components.address_line_1,
    components.addressLine2 || components.address_line_2,
  ]
    .filter((i) => i)
    .join(" ");

  const address_last = [
    components.state?.length === 2
      ? components.state.toUpperCase()
      : components.state,
    components.zipCode || components.zip_code,
  ]
    .filter((i) => i)
    .join(" ");

  const address = [address_first, components.city, address_last];

  return address.filter((i) => i).join(", ");
};

export const addKeyFromId = (array) => {
  return Array.isArray(array) && array.length
    ? array.map((element) => {
        return { ...element, key: element.id };
      })
    : [];
};

export const dedupeByKey = (arr, k) => {
  const byKey = {};
  arr.forEach((item) => {
    byKey[item[k]] = item;
  });
  return Object.values(byKey);
};

// TODO: rename this, might be mistaken as a hook
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getErrorMessage = (err) => {
  let msg = "Sorry, there was a problem with your request.";
  const response = err.response;
  if (response) {
    const data = response.data;
    if (data.message) {
      msg = data.message;
    } else if (typeof data === "object") {
      // Get message from object prop with array value
      const errors = Object.values(data);
      msg = Array.from(new Set(errors.flat())).join(", ");
    }
    return msg;
  }

  // log error to 3rd party (Rollbar or Sentry)
  // Temp: log javascript errors to console
  console.error(err);
  return msg;
};

export const debounce = (func, delay) => {
  let timeout;

  return function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
  };
};

// export const getCreateTime = (text) => {
//   const now = new Date();
//   const createDate = new Date(text);
//   const createYear = createDate.getFullYear();
//   const createMonth = createDate.getMonth();
//   const createDateNumber = createDate.getDate();
//   if (
//     now.getFullYear() === createYear &&
//     now.getMonth() === createMonth &&
//     now.getDate() === createDateNumber
//   ) {
//     return moment(text).format("[Today at] LT");
//   } else {
//     return moment(text).format("ll");
//   }
// };

export const compact = (array) => {
  let resIndex = 0;
  const result = [];

  if (array == null) {
    return result;
  }

  for (const value of array) {
    if (value) {
      result[resIndex++] = value;
    }
  }
  return result;
};

export const toSentenceCase = (string) => {
  return string
    ? string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
    : "";
};

export const toTitleCase = (string) => {
  return string ? toSentenceCase(string.replaceAll("_", " ")) : "";
};

export const extractBiographySlug = (biography) => {
  return biography.alias ? biography.alias : biography.slug;
};

export const formatPhoneNumber = (text) => {
  const cleaned = ("" + text).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "",
      number = `${intlCode}(${match[2]})${match[3]}-${match[4]}`;
    return number;
  }
  return text;
};

export const stringComarator = ({ ignoreCase = false }) => {
  // sort by name
  return (a, b) => {
    const _a = ignoreCase ? a.toUpperCase() : a;
    const _b = ignoreCase ? b.toUpperCase() : b;

    if (_a < _b) {
      return -1;
    }
    if (_a > _b) {
      return 1;
    }
    // names must be equal
    return 0;
  };
};

export const roundAccurately = (number, decimalPlaces) => {
  return Number(
    Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces
  );
};

export const strCompare = (a, b) => {
  if (a && b) {
    return a.toString().localeCompare(b);
  }
  if (a) {
    return -1;
  }
  if (b) {
    return 1;
  }
  return 0;
};

export const formatBytes = (bytes) => {
  if (bytes > 999999) {
    return Math.round(bytes / 1000000) + "MB";
  }
  return Math.round(bytes / 1000) + "kB";
};

export const featureEnabled = (optionalFlagName = "beta") => {
  const queryParams = useQuery(); // eslint-disable-line react-hooks/rules-of-hooks

  if (queryParams.get("beta") === "false" || queryParams.get("beta") === "0") {
    return false;
  }

  return (
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "staging" ||
    process.env.REACT_APP_ENV === "test" ||
    process.env.NODE_ENV === "development" ||
    queryParams.get("beta") || // This is null (falsey) if not set
    queryParams.get(optionalFlagName)
  );
};

// gets the tool tip message for investment profiles
export const getToolTipMsgInvestmentProfile = (value) => {
  switch (value) {
    case "individual":
      return "This can be your SSN or ITIN (if applicable)";
    case "joint_account":
      return "This can be your SSN or ITIN (if applicable)";
    case "ira":
      return "Usually this is the tax ID for your IRA and not your SSN";
    case "entity":
      return "Usually this is the tax ID for your entity and not your SSN";
    default:
      return "This can be your SSN, TIN or ITIN (if applicable)";
  }
};

export const getShortUrl = (path) => {
  return window.location.host.replace("www.", "") + path;
};

export const getFullUrl = (path) => {
  return window.location.origin + path;
};

const reservedSubdomains = [
  /^e?mail\d*$/,
  /^auth$/,
  /^login$/,
  /^staging-login$/,
  /^staging-auth$/,
  /^staging$/,
  /^blog$/,
  /^www$/,
];

export const getCustomSubdomain = () => {
  const subdomain = window.location.hostname.split(".")[0];
  // todo: better way to check this, maybe based on env
  const testing =
    !process.env.REACT_APP_ENV ||
    (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "test");
  const subdomains = reservedSubdomains;
  for (const reservedSubdomain in subdomains) {
    if (subdomain.match(subdomains[reservedSubdomain])) {
      return null;
    }
  }
  if (
    window.location.hostname.includes("herokuapp.com") ||
    (testing &&
      (subdomain === "localhost" ||
        window.location.hostname.includes("loca.lt") ||
        window.location.hostname.includes("ngrok.io") ||
        window.location.hostname.includes("stunel.io") ||
        window.location.hostname.includes("192.168")))
  ) {
    return null;
  }
  return subdomain;
};
export const getVisibleCountries = () => {
  if (featureEnabled("show_all_countries")) {
    return getCountries().map((country) => country.alpha2);
  }

  return VISIBLE_COUNTRIES;
};

export const switchToGeneralPortal = () => {
  const subdomain = getCustomSubdomain();
  const path = window.location.href;
  if (subdomain) {
    if (path.includes("localhost")) {
      window.location = path.replace(`${subdomain}.`, "");
    } else {
      window.location = path.replace(subdomain, "www");
    }
  }
};

export const switchToCustomPortal = (subdomain) => {
  const hostname =
    window.location.host.split(".").slice(1).join(".") || window.location.host;
  const portalUrl = `${subdomain}.${hostname}`;

  window.location = `//${portalUrl}`;
};

export const getAverageCoc = (metrics) => {
  let total = 0;
  const defaultTerm = metrics?.default_term?.value || metrics?.default_term;
  const terms = defaultTerm.split(",");
  const maxTerm = terms.length === 1 ? terms[0] : terms[1];

  for (let i = 0; i < maxTerm; i++) {
    const metric = metrics[`default_estimated_year_${i + 1}`];
    if (metric && !metric.isDisabled) {
      total += Number(metric.value || metric);
    }
  }

  return roundAccurately(total / maxTerm, 2);
};

export const emailWordBreak = (email) => {
  if (!email) return "";
  const emailSplit = email.split("@");
  return (
    <>
      {emailSplit[0]}
      <wbr />@{emailSplit[1]}
    </>
  );
};

export const formatYearRange = (input) => {
  const split = input.split(",");
  if (split.length === 1 || split[0] === split[1]) {
    return split[0] + " years";
  } else {
    return split[0] + " to " + split[1] + " years";
  }
};

export const formatName = (firstName, lastName) => {
  if (firstName && lastName) {
    return firstName + " " + lastName;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return undefined;
};

export const toKebabCase = (title) => {
  return title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const isUserLoggedOut = () => {
  const token = getToken();
  const cookieToken = getCookieToken();
  return !token && !cookieToken;
};
