import {
  getAllowedDomains,
  getCashflowMarketplaceUrl,
  getWriteOnlyDomains,
  parentDomain,
  sendPayload,
} from "common/constants/sso";
import {
  setToken,
  setRefreshToken,
  getToken,
  getCookieToken,
  getCookieRefreshToken,
  deleteToken,
  deleteRefreshToken,
  isIncognito,
  firebaseTokenKey,
  setFirebaseToken,
  deleteFirebaseToken,
  getFirebaseToken,
  add_cookie, getCookieFirebaseToken, getRefreshToken, tokenCookieKey, refreshTokenCookieKey, firebaseTokenCookieKey,
} from "lib/helpers";
import { clear, clearCredentialCookies } from "lib/helpers";
import { logout, resetState } from "features/Auth/Login/loginSlice";
import isNull from "lodash/isNull";
import { useDispatch } from "react-redux";
import { getStore } from "configureStore";
import { getCurrentUser } from "layouts/MainLayout/currentUserSlice";
import { tokenKey, refreshTokenKey } from "lib/helpers";

interface EventInterface {
  data: EventMessage;
  origin: string;
}
interface EventMessage {
  message: string;
  accessToken?: string;
  refreshToken?: string;
  firebaseToken?: string;
  redirectUrl?: string;
  key?: string;
  newValue?: string;
  isIncognito?: boolean;
}
interface LocalStorageMessage {
  message: string;
  key?: string;
  newValue?: string;
}

const useSSO = () => {
  const store = getStore();
  const dispatch = useDispatch<typeof store.dispatch>();

  const handleEvent = async (event: EventInterface) => {
    switch (event.data.message) {
      case "LoadedIFrame":
        if (!getAllowedDomains().includes(event.origin)) break;

        parentDomain.parentOrigin = event.origin;
        if (getToken() && !getCookieToken() && !event.data.accessToken) {
          await destroySession();
          logoutOrigin(parentDomain.parentOrigin);
          break;
        }
        handleSafariOneWayCookies(event.data)
        if (getCookieToken() || getRefreshToken()) {
          await dispatch(getCurrentUser());
        }
        
        
        shareCookies();
        break;

      case "Logout":
        if (!getAllowedDomains().includes(event.origin)) break;
       
        await destroySession();
        logoutOrigin(event.origin);
        break;

      case "RefreshToken":
        if (!getAllowedDomains().includes(event.origin)) break;

        await dispatch(getCurrentUser());
        if (getCookieToken()) {
          sendRefreshMessage();
        }
        break;

      case "SetLocalStorage":
        if (
          !getAllowedDomains().includes(event.origin) &&
          !getWriteOnlyDomains().includes(event.origin)
        )
          break;

        handleStorageSet(event.data);
        break;
    }
  };

  //On safari, SSO is cleared when safari is closed, but localstorage can persist, this effectively logs the user out. If SSO is empty and host has keys, populate from host.
  const handleSafariOneWayCookies = (data: EventMessage) => {
    if (!getToken() && !getCookieToken() && !getRefreshToken() && !getCookieRefreshToken()) {
      if (data.accessToken) {
        setToken(data.accessToken)
        add_cookie(tokenCookieKey,data.accessToken)
      }
      if (data.refreshToken) {
        setRefreshToken(data.refreshToken)
        add_cookie(refreshTokenCookieKey,data.refreshToken)
      }
      if (data.firebaseToken) {
        setFirebaseToken(data.firebaseToken)
        add_cookie(firebaseTokenCookieKey,data.firebaseToken)
      }
    }
    
  }

  const handleStorage = (event: StorageEvent) => {
    if (event.key === tokenKey || event.key === firebaseTokenKey) {
      updateLocalStorage(parentDomain.parentOrigin, event.key, event.newValue);
    } else if (event.key === refreshTokenKey) {
      updateLocalStorage(parentDomain.parentOrigin, event.key, event.newValue);
      if (isNull(event.newValue)) {
        logoutOrigin(parentDomain.parentOrigin);
      }
    }
  };

  const handleStorageSet = (message: LocalStorageMessage) => {
    if (message.key === tokenKey) {
      if (message.newValue === "") {
        deleteToken();
      } else if (message.newValue) {
        setToken(message.newValue);
      }
    } else if (message.key === refreshTokenKey) {
      if (message.newValue === "") {
        deleteRefreshToken();
      } else if (message.newValue) {
        setRefreshToken(message.newValue);
      }
    } else if (message.key === firebaseTokenKey) {
      if (message.newValue === "") {
        deleteFirebaseToken();
      } else if (message.newValue) {
        setFirebaseToken(message.newValue);
        add_cookie("__firebase_token", message.newValue);

      }
    }
  };

  const shareCookies = () => {
    const cookieToken = getCookieToken() || getToken();
    const refreshToken = getCookieRefreshToken() || getRefreshToken();
    // use localStorage here since we're not storing this in cookies
    const firebaseToken = getCookieFirebaseToken() || getFirebaseToken();
    const incognito = isIncognito();
    const message = {
      message: "SharingCookies",
      accessToken: cookieToken,
      refreshToken: refreshToken,
      firebaseToken: firebaseToken,
      isIncognito: incognito,
    };
    sendPayload(message);
  };

  const updateLocalStorage = (
    sendDomain: string,
    key: string,
    newValue: string | null
  ) => {
    const message = {
      message: "UpdateLocalStorage",
      key: key,
      newValue: newValue,
    };
    if (getAllowedDomains().includes(sendDomain)) {
      sendPayload(message);
    }
  };

  const destroySession = async () => {
    clearCredentialCookies();
    dispatch(resetState());
    deleteToken();
    deleteRefreshToken();
    deleteFirebaseToken();
    clear();
    await dispatch(logout());
    shareCookies();
  };

  const logoutOrigin = (sendDomain: string) => {
    const message = {
      message: "LogoutSuccess",
    };
    if (getAllowedDomains().includes(sendDomain)) {
      sendPayload(message);
    }
  };

  const sendLoginPayload = (loginPayload: any) => {
    sendPayload(loginPayload);
  };

  const isCashflowMarketplace = () => {
    return getCashflowMarketplaceUrl().includes(parentDomain.parentOrigin);
  };

  const sendRefreshMessage = () => {
    const refreshToken = getCookieRefreshToken();
    const payload: EventMessage = {
      message: "RefreshReturn",
      accessToken: getCookieToken(),
      refreshToken: refreshToken,
    };
    sendPayload(payload);
  };

  return {
    handleEvent,
    handleStorage,
    isCashflowMarketplace,
    sendLoginPayload,
    sendPayload,
  };
};

export default useSSO;
